@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Outfit:wght@100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

body {
  margin: 0;
  /* font-family: "Plus Jakarta Sans", sans-serif; */
  font-family: "Outfit", sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}
h1, h2, h3, h4, h5 {
  font-family: "Outfit", sans-serif!important;
  font-weight: bold;
}
.bottom-32-5 {
  bottom: 7rem;
}
.mb-22 {
  margin-bottom: 6rem;
}
.min-w-220 {
  min-width: 220px;
}
.w-220 {
  width: 180px;
}
.w-25 {
  width: 6rem;
}
.w-60 {
  width: 60px;
}
.w-50 {
  width: 50px;
}
.px-8 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.line-h12 {
  line-height: 1.2;
}
.hover-pointer:hover {
  cursor: pointer;
}
.checkbox-flex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  margin: auto;
  justify-content: space-between;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}